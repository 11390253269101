/* tslint:disable */
/* eslint-disable */
/**
 * Eduthings.SourceGen.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface District
 */
export interface District {
    /**
     * 
     * @type {number}
     * @memberof District
     */
    districtId: number;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    name: string | null;
    /**
     * 
     * @type {Date}
     * @memberof District
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof District
     */
    modifiedDate: Date;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    careerPlanStudentGoalChoicesList: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    careerPlanParentGoalChoicesList: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    careerPlanCampusGoalChoicesList: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    careerPlanPostSecondaryPlanChoicesList: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    careerPlanInterventionChoicesList: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    parentPortalAuthenticationUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    featureList: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    courseSelectionSemesterList: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    courseSelectionSequenceList: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    courseSelectionArrangementList: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof District
     */
    autoselectPosForCoding: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof District
     */
    courseSelectionActive: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    courseSelectionInactiveMessage: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    subdomain: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    ldapServerAddress: string | null;
    /**
     * 
     * @type {number}
     * @memberof District
     */
    ldapPort: number | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    ldapOu: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof District
     */
    filterPosOnEndorsement: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof District
     */
    autoselectPosScheduleWeight: number | null;
    /**
     * 
     * @type {number}
     * @memberof District
     */
    numberOfInterestProfilerQuestions: number | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    ldapEduthingsDn: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    ldapEduthingsEncryptedPassword: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof District
     */
    ldapUseEncryption: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    ignoreSingleServiceIdListForPosAutoselect: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof District
     */
    codeIntentAsActualForSelectedPos: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof District
     */
    hideGreyedOutCoursesOn4yp: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof District
     */
    showSignatureLineOn4ypPgpPrint: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof District
     */
    onlyAllow8thGradeToEditCoursesOnParentPortal: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof District
     */
    endOfYearAdjustment: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof District
     */
    prettyPrint4yp: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof District
     */
    autoselectPosDefaultCourseWeight: number | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    pgpStateAssessmentChoicesList: string | null;
    /**
     * 
     * @type {number}
     * @memberof District
     */
    fteAttendancePercent: number | null;
    /**
     * 
     * @type {number}
     * @memberof District
     */
    fundingPerFte: number | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    studentSubdomain: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof District
     */
    runFullCodingOnNextImport: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    userSsoSubdomain: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    ssoMetadataUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    ldapSearchField: string | null;
    /**
     * 
     * @type {number}
     * @memberof District
     */
    creditsPerYear: number | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    usState: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    darkBgColor: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    linkColor: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    menuBgColor: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    mediumBgColor: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    secondMenuBgColor: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    mainDbName: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    userSsoMetadataUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    parentPortalDisclaimer: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    enabledWageAreas: string | null;
    /**
     * 
     * @type {number}
     * @memberof District
     */
    fteEfficiencyBaseline: number | null;
    /**
     * 
     * @type {number}
     * @memberof District
     */
    fteEfficiencyMinimum: number | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    onlyAllowTheseGradesToEditCoursesOnParentPortal: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    region: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    excludeTeacherCtePctServiceIdsList: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof District
     */
    currentCustomer: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    samlMetadataContent: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    cleverId: string | null;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    userSamlMetadataContent: string | null;
}

/**
 * Check if a given object implements the District interface.
 */
export function instanceOfDistrict(value: object): value is District {
    if (!('districtId' in value) || value['districtId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    if (!('modifiedDate' in value) || value['modifiedDate'] === undefined) return false;
    if (!('careerPlanStudentGoalChoicesList' in value) || value['careerPlanStudentGoalChoicesList'] === undefined) return false;
    if (!('careerPlanParentGoalChoicesList' in value) || value['careerPlanParentGoalChoicesList'] === undefined) return false;
    if (!('careerPlanCampusGoalChoicesList' in value) || value['careerPlanCampusGoalChoicesList'] === undefined) return false;
    if (!('careerPlanPostSecondaryPlanChoicesList' in value) || value['careerPlanPostSecondaryPlanChoicesList'] === undefined) return false;
    if (!('careerPlanInterventionChoicesList' in value) || value['careerPlanInterventionChoicesList'] === undefined) return false;
    if (!('parentPortalAuthenticationUrl' in value) || value['parentPortalAuthenticationUrl'] === undefined) return false;
    if (!('featureList' in value) || value['featureList'] === undefined) return false;
    if (!('courseSelectionSemesterList' in value) || value['courseSelectionSemesterList'] === undefined) return false;
    if (!('courseSelectionSequenceList' in value) || value['courseSelectionSequenceList'] === undefined) return false;
    if (!('courseSelectionArrangementList' in value) || value['courseSelectionArrangementList'] === undefined) return false;
    if (!('autoselectPosForCoding' in value) || value['autoselectPosForCoding'] === undefined) return false;
    if (!('courseSelectionActive' in value) || value['courseSelectionActive'] === undefined) return false;
    if (!('courseSelectionInactiveMessage' in value) || value['courseSelectionInactiveMessage'] === undefined) return false;
    if (!('subdomain' in value) || value['subdomain'] === undefined) return false;
    if (!('ldapServerAddress' in value) || value['ldapServerAddress'] === undefined) return false;
    if (!('ldapPort' in value) || value['ldapPort'] === undefined) return false;
    if (!('ldapOu' in value) || value['ldapOu'] === undefined) return false;
    if (!('filterPosOnEndorsement' in value) || value['filterPosOnEndorsement'] === undefined) return false;
    if (!('autoselectPosScheduleWeight' in value) || value['autoselectPosScheduleWeight'] === undefined) return false;
    if (!('numberOfInterestProfilerQuestions' in value) || value['numberOfInterestProfilerQuestions'] === undefined) return false;
    if (!('ldapEduthingsDn' in value) || value['ldapEduthingsDn'] === undefined) return false;
    if (!('ldapEduthingsEncryptedPassword' in value) || value['ldapEduthingsEncryptedPassword'] === undefined) return false;
    if (!('ldapUseEncryption' in value) || value['ldapUseEncryption'] === undefined) return false;
    if (!('ignoreSingleServiceIdListForPosAutoselect' in value) || value['ignoreSingleServiceIdListForPosAutoselect'] === undefined) return false;
    if (!('codeIntentAsActualForSelectedPos' in value) || value['codeIntentAsActualForSelectedPos'] === undefined) return false;
    if (!('hideGreyedOutCoursesOn4yp' in value) || value['hideGreyedOutCoursesOn4yp'] === undefined) return false;
    if (!('showSignatureLineOn4ypPgpPrint' in value) || value['showSignatureLineOn4ypPgpPrint'] === undefined) return false;
    if (!('onlyAllow8thGradeToEditCoursesOnParentPortal' in value) || value['onlyAllow8thGradeToEditCoursesOnParentPortal'] === undefined) return false;
    if (!('endOfYearAdjustment' in value) || value['endOfYearAdjustment'] === undefined) return false;
    if (!('prettyPrint4yp' in value) || value['prettyPrint4yp'] === undefined) return false;
    if (!('autoselectPosDefaultCourseWeight' in value) || value['autoselectPosDefaultCourseWeight'] === undefined) return false;
    if (!('pgpStateAssessmentChoicesList' in value) || value['pgpStateAssessmentChoicesList'] === undefined) return false;
    if (!('fteAttendancePercent' in value) || value['fteAttendancePercent'] === undefined) return false;
    if (!('fundingPerFte' in value) || value['fundingPerFte'] === undefined) return false;
    if (!('studentSubdomain' in value) || value['studentSubdomain'] === undefined) return false;
    if (!('runFullCodingOnNextImport' in value) || value['runFullCodingOnNextImport'] === undefined) return false;
    if (!('userSsoSubdomain' in value) || value['userSsoSubdomain'] === undefined) return false;
    if (!('ssoMetadataUrl' in value) || value['ssoMetadataUrl'] === undefined) return false;
    if (!('ldapSearchField' in value) || value['ldapSearchField'] === undefined) return false;
    if (!('creditsPerYear' in value) || value['creditsPerYear'] === undefined) return false;
    if (!('usState' in value) || value['usState'] === undefined) return false;
    if (!('darkBgColor' in value) || value['darkBgColor'] === undefined) return false;
    if (!('linkColor' in value) || value['linkColor'] === undefined) return false;
    if (!('menuBgColor' in value) || value['menuBgColor'] === undefined) return false;
    if (!('mediumBgColor' in value) || value['mediumBgColor'] === undefined) return false;
    if (!('secondMenuBgColor' in value) || value['secondMenuBgColor'] === undefined) return false;
    if (!('mainDbName' in value) || value['mainDbName'] === undefined) return false;
    if (!('userSsoMetadataUrl' in value) || value['userSsoMetadataUrl'] === undefined) return false;
    if (!('parentPortalDisclaimer' in value) || value['parentPortalDisclaimer'] === undefined) return false;
    if (!('enabledWageAreas' in value) || value['enabledWageAreas'] === undefined) return false;
    if (!('fteEfficiencyBaseline' in value) || value['fteEfficiencyBaseline'] === undefined) return false;
    if (!('fteEfficiencyMinimum' in value) || value['fteEfficiencyMinimum'] === undefined) return false;
    if (!('onlyAllowTheseGradesToEditCoursesOnParentPortal' in value) || value['onlyAllowTheseGradesToEditCoursesOnParentPortal'] === undefined) return false;
    if (!('region' in value) || value['region'] === undefined) return false;
    if (!('excludeTeacherCtePctServiceIdsList' in value) || value['excludeTeacherCtePctServiceIdsList'] === undefined) return false;
    if (!('currentCustomer' in value) || value['currentCustomer'] === undefined) return false;
    if (!('samlMetadataContent' in value) || value['samlMetadataContent'] === undefined) return false;
    if (!('cleverId' in value) || value['cleverId'] === undefined) return false;
    if (!('userSamlMetadataContent' in value) || value['userSamlMetadataContent'] === undefined) return false;
    return true;
}

export function DistrictFromJSON(json: any): District {
    return DistrictFromJSONTyped(json, false);
}

export function DistrictFromJSONTyped(json: any, ignoreDiscriminator: boolean): District {
    if (json == null) {
        return json;
    }
    return {
        
        'districtId': json['districtId'],
        'name': json['name'],
        'createdDate': (new Date(json['createdDate'])),
        'modifiedDate': (new Date(json['modifiedDate'])),
        'careerPlanStudentGoalChoicesList': json['careerPlanStudentGoalChoicesList'],
        'careerPlanParentGoalChoicesList': json['careerPlanParentGoalChoicesList'],
        'careerPlanCampusGoalChoicesList': json['careerPlanCampusGoalChoicesList'],
        'careerPlanPostSecondaryPlanChoicesList': json['careerPlanPostSecondaryPlanChoicesList'],
        'careerPlanInterventionChoicesList': json['careerPlanInterventionChoicesList'],
        'parentPortalAuthenticationUrl': json['parentPortalAuthenticationUrl'],
        'featureList': json['featureList'],
        'courseSelectionSemesterList': json['courseSelectionSemesterList'],
        'courseSelectionSequenceList': json['courseSelectionSequenceList'],
        'courseSelectionArrangementList': json['courseSelectionArrangementList'],
        'autoselectPosForCoding': json['autoselectPosForCoding'],
        'courseSelectionActive': json['courseSelectionActive'],
        'courseSelectionInactiveMessage': json['courseSelectionInactiveMessage'],
        'subdomain': json['subdomain'],
        'ldapServerAddress': json['ldapServerAddress'],
        'ldapPort': json['ldapPort'],
        'ldapOu': json['ldapOu'],
        'filterPosOnEndorsement': json['filterPosOnEndorsement'],
        'autoselectPosScheduleWeight': json['autoselectPosScheduleWeight'],
        'numberOfInterestProfilerQuestions': json['numberOfInterestProfilerQuestions'],
        'ldapEduthingsDn': json['ldapEduthingsDn'],
        'ldapEduthingsEncryptedPassword': json['ldapEduthingsEncryptedPassword'],
        'ldapUseEncryption': json['ldapUseEncryption'],
        'ignoreSingleServiceIdListForPosAutoselect': json['ignoreSingleServiceIdListForPosAutoselect'],
        'codeIntentAsActualForSelectedPos': json['codeIntentAsActualForSelectedPos'],
        'hideGreyedOutCoursesOn4yp': json['hideGreyedOutCoursesOn4yp'],
        'showSignatureLineOn4ypPgpPrint': json['showSignatureLineOn4ypPgpPrint'],
        'onlyAllow8thGradeToEditCoursesOnParentPortal': json['onlyAllow8thGradeToEditCoursesOnParentPortal'],
        'endOfYearAdjustment': json['endOfYearAdjustment'],
        'prettyPrint4yp': json['prettyPrint4yp'],
        'autoselectPosDefaultCourseWeight': json['autoselectPosDefaultCourseWeight'],
        'pgpStateAssessmentChoicesList': json['pgpStateAssessmentChoicesList'],
        'fteAttendancePercent': json['fteAttendancePercent'],
        'fundingPerFte': json['fundingPerFte'],
        'studentSubdomain': json['studentSubdomain'],
        'runFullCodingOnNextImport': json['runFullCodingOnNextImport'],
        'userSsoSubdomain': json['userSsoSubdomain'],
        'ssoMetadataUrl': json['ssoMetadataUrl'],
        'ldapSearchField': json['ldapSearchField'],
        'creditsPerYear': json['creditsPerYear'],
        'usState': json['usState'],
        'darkBgColor': json['darkBgColor'],
        'linkColor': json['linkColor'],
        'menuBgColor': json['menuBgColor'],
        'mediumBgColor': json['mediumBgColor'],
        'secondMenuBgColor': json['secondMenuBgColor'],
        'mainDbName': json['mainDbName'],
        'userSsoMetadataUrl': json['userSsoMetadataUrl'],
        'parentPortalDisclaimer': json['parentPortalDisclaimer'],
        'enabledWageAreas': json['enabledWageAreas'],
        'fteEfficiencyBaseline': json['fteEfficiencyBaseline'],
        'fteEfficiencyMinimum': json['fteEfficiencyMinimum'],
        'onlyAllowTheseGradesToEditCoursesOnParentPortal': json['onlyAllowTheseGradesToEditCoursesOnParentPortal'],
        'region': json['region'],
        'excludeTeacherCtePctServiceIdsList': json['excludeTeacherCtePctServiceIdsList'],
        'currentCustomer': json['currentCustomer'],
        'samlMetadataContent': json['samlMetadataContent'],
        'cleverId': json['cleverId'],
        'userSamlMetadataContent': json['userSamlMetadataContent'],
    };
}

export function DistrictToJSON(value?: District | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'districtId': value['districtId'],
        'name': value['name'],
        'createdDate': ((value['createdDate']).toISOString()),
        'modifiedDate': ((value['modifiedDate']).toISOString()),
        'careerPlanStudentGoalChoicesList': value['careerPlanStudentGoalChoicesList'],
        'careerPlanParentGoalChoicesList': value['careerPlanParentGoalChoicesList'],
        'careerPlanCampusGoalChoicesList': value['careerPlanCampusGoalChoicesList'],
        'careerPlanPostSecondaryPlanChoicesList': value['careerPlanPostSecondaryPlanChoicesList'],
        'careerPlanInterventionChoicesList': value['careerPlanInterventionChoicesList'],
        'parentPortalAuthenticationUrl': value['parentPortalAuthenticationUrl'],
        'featureList': value['featureList'],
        'courseSelectionSemesterList': value['courseSelectionSemesterList'],
        'courseSelectionSequenceList': value['courseSelectionSequenceList'],
        'courseSelectionArrangementList': value['courseSelectionArrangementList'],
        'autoselectPosForCoding': value['autoselectPosForCoding'],
        'courseSelectionActive': value['courseSelectionActive'],
        'courseSelectionInactiveMessage': value['courseSelectionInactiveMessage'],
        'subdomain': value['subdomain'],
        'ldapServerAddress': value['ldapServerAddress'],
        'ldapPort': value['ldapPort'],
        'ldapOu': value['ldapOu'],
        'filterPosOnEndorsement': value['filterPosOnEndorsement'],
        'autoselectPosScheduleWeight': value['autoselectPosScheduleWeight'],
        'numberOfInterestProfilerQuestions': value['numberOfInterestProfilerQuestions'],
        'ldapEduthingsDn': value['ldapEduthingsDn'],
        'ldapEduthingsEncryptedPassword': value['ldapEduthingsEncryptedPassword'],
        'ldapUseEncryption': value['ldapUseEncryption'],
        'ignoreSingleServiceIdListForPosAutoselect': value['ignoreSingleServiceIdListForPosAutoselect'],
        'codeIntentAsActualForSelectedPos': value['codeIntentAsActualForSelectedPos'],
        'hideGreyedOutCoursesOn4yp': value['hideGreyedOutCoursesOn4yp'],
        'showSignatureLineOn4ypPgpPrint': value['showSignatureLineOn4ypPgpPrint'],
        'onlyAllow8thGradeToEditCoursesOnParentPortal': value['onlyAllow8thGradeToEditCoursesOnParentPortal'],
        'endOfYearAdjustment': value['endOfYearAdjustment'],
        'prettyPrint4yp': value['prettyPrint4yp'],
        'autoselectPosDefaultCourseWeight': value['autoselectPosDefaultCourseWeight'],
        'pgpStateAssessmentChoicesList': value['pgpStateAssessmentChoicesList'],
        'fteAttendancePercent': value['fteAttendancePercent'],
        'fundingPerFte': value['fundingPerFte'],
        'studentSubdomain': value['studentSubdomain'],
        'runFullCodingOnNextImport': value['runFullCodingOnNextImport'],
        'userSsoSubdomain': value['userSsoSubdomain'],
        'ssoMetadataUrl': value['ssoMetadataUrl'],
        'ldapSearchField': value['ldapSearchField'],
        'creditsPerYear': value['creditsPerYear'],
        'usState': value['usState'],
        'darkBgColor': value['darkBgColor'],
        'linkColor': value['linkColor'],
        'menuBgColor': value['menuBgColor'],
        'mediumBgColor': value['mediumBgColor'],
        'secondMenuBgColor': value['secondMenuBgColor'],
        'mainDbName': value['mainDbName'],
        'userSsoMetadataUrl': value['userSsoMetadataUrl'],
        'parentPortalDisclaimer': value['parentPortalDisclaimer'],
        'enabledWageAreas': value['enabledWageAreas'],
        'fteEfficiencyBaseline': value['fteEfficiencyBaseline'],
        'fteEfficiencyMinimum': value['fteEfficiencyMinimum'],
        'onlyAllowTheseGradesToEditCoursesOnParentPortal': value['onlyAllowTheseGradesToEditCoursesOnParentPortal'],
        'region': value['region'],
        'excludeTeacherCtePctServiceIdsList': value['excludeTeacherCtePctServiceIdsList'],
        'currentCustomer': value['currentCustomer'],
        'samlMetadataContent': value['samlMetadataContent'],
        'cleverId': value['cleverId'],
        'userSamlMetadataContent': value['userSamlMetadataContent'],
    };
}


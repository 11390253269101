import { AuthContext } from "@eduthings/app-ui-scripts/security/AuthContext";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import DistrictSwitcher from "./components/DistrictSwitcher";

const authContext = new AuthContext();

const rootElement = document.getElementById("layout-app-root");
if (rootElement) {
    authContext.loadSate().then((state) => {
        const root = ReactDOM.createRoot(rootElement);
        root.render(
            <StrictMode>
                <DistrictSwitcher authContext={authContext} />
            </StrictMode>
        );
    });
}

import { AuthenticationApi } from "@eduthings/app-scripts/generated/apis/AuthenticationApi";
import { DistrictApi } from "@eduthings/app-scripts/generated/apis/DistrictApi";
import { action, observable } from "mobx";

export class DistrictSwitcherViewModel {
    constructor() {
        document.addEventListener("visibilitychange", () => {
            console.log(`visibilitychange ${document.hidden}`);
            if (!document.hidden) {
                this.validateDistrictIsSame();
            }
        });
    }

    @observable
    accessor districtId = 0;

    @observable
    accessor districtName: string | undefined;

    @observable
    accessor newDistrictName: string | undefined;

    @observable
    accessor newDistrictId = 0;

    @observable
    accessor isVisible = false;

    @action
    async validateDistrictIsSame() {
        const client = new AuthenticationApi();
        const currentState = await client.get();
        if (
            currentState.districtId != this.districtId &&
            currentState.districtId
        ) {
            const districtClient = new DistrictApi();

            const currentDistrict = await districtClient.get(this.districtId);
            const newDistrict = await districtClient.get(
                currentState.districtId
            );

            this.districtName = currentDistrict?.name ?? "";
            this.newDistrictName = newDistrict?.name ?? "";
            this.newDistrictId = newDistrict.districtId;

            this.isVisible = true;
        } else {
            this.isVisible = false;
        }
    }
}
